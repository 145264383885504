import { PagefindDocument } from "../types/search.ts";

interface SearchResultProps {
  item: PagefindDocument;
}

export default function SearchResult({ item }: SearchResultProps) {
  const {
    content,
    excerpt,
    meta: { title, date },
    url,
  } = item;

  // Check if the first 5 and last 5 characters of the whole content and the
  // excerpt are the same. If they aren't, that means the excerpt didn't end or
  // start the content
  const startEllipse = content.slice(0, 5) !== excerpt.slice(0, 5) ? "…" : "";
  const endEllipse = content.slice(-5) !== excerpt.slice(-5) ? "…" : "";

  return (
    <section className="search-result">
      <span>{date}</span>
      <a data-cy="search-result-item" href={url}>
        <h5>{title}</h5>
        <p
          dangerouslySetInnerHTML={{
            __html: `${startEllipse}${excerpt}${endEllipse}`,
          }}
        />
      </a>
    </section>
  );
}
