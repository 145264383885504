import { useEffect, useReducer, useState } from "react";
import {
  Pagefind,
  PagefindSearchOptions,
  UsePromiseAllReturn,
  UsePromiseAllStates,
  UseSearchReducerAction,
  UseSearchReducerActionType,
  UseSearchReducerState,
  UseSearchReturn,
  UseSearchStates,
} from "../types/search.ts";

export function usePromiseAll<T>(
  promises: Promise<T>[],
): UsePromiseAllReturn<T> {
  const [results, setResults] = useState<T[]>([]);
  const [loadState, setLoadState] = useState<UsePromiseAllStates>(
    UsePromiseAllStates.LOADING,
  );

  useEffect(() => {
    if (promises.length === 0) {
      setResults([]);
      setLoadState(UsePromiseAllStates.FINISHED);
      return;
    }

    const loadPromises = async () => {
      setLoadState(UsePromiseAllStates.LOADING);

      const awaitedResults = await Promise.all(promises);

      setResults(awaitedResults);
      setLoadState(UsePromiseAllStates.FINISHED);
    };

    loadPromises().then();
  }, [promises]);

  return { results, loadState };
}

declare global {
  var pagefindLoading: boolean;
  var pagefind: Pagefind;
}

export function usePagefind() {
  useEffect(() => {
    async function loadPagefind() {
      if (!globalThis.pagefindLoading) {
        try {
          globalThis.pagefindLoading = true;

          // We do this like this so that esbuild and deno don't try
          // and import the file at build time
          const origin = `${window.location.protocol}//${window.location.host}`;

          globalThis.pagefind = await import(
            `${origin}/pagefind/pagefind.js`
          );

          await globalThis.pagefind.options?.({ indexWeight: 4 });
        } catch (_e) {
          globalThis.pagefind = {
            search: () => new Promise((resolve) => resolve({ results: [] })),
            debouncedSearch: () =>
              new Promise((resolve) => resolve({ results: [] })),
          };
        }
      }
    }

    loadPagefind().then();
  }, []);
}

function useSearchReducer(
  state: UseSearchReducerState,
  action: UseSearchReducerAction,
): UseSearchReducerState {
  const { payload, type } = action;

  switch (type) {
    case UseSearchReducerActionType.ERROR_PAGEFIND_NOT_FOUND: {
      return {
        ...state,
        searchState: UseSearchStates.ERROR,
      };
    }

    case UseSearchReducerActionType.ERROR_HANDLING_RETRY_PAGEFIND_NOT_FOUND: {
      return {
        ...state,
        searchState: UseSearchStates.INIT,
        timesErrored: state.timesErrored + 1,
      };
    }

    case UseSearchReducerActionType.INIT_SETUP_NEW_QUERY: {
      return {
        ...state,
        searchState: UseSearchStates.INIT,
      };
    }

    case UseSearchReducerActionType.SEARCH_PREPARE: {
      return {
        ...state,
        searchState: UseSearchStates.LOADING,
        lastQuery: payload?.lastQuery ?? "",
      };
    }

    case UseSearchReducerActionType.SEARCH_FINISH: {
      return {
        ...state,
        searchState: UseSearchStates.FINISHED,
        results: payload?.results ?? [],
      };
    }

    case UseSearchReducerActionType.SEARCH_FORCE: {
      return {
        ...state,
        searchState: UseSearchStates.INIT,
        lastQuery: "",
      };
    }

    default: {
      throw new Error(`UseSearchReducer: unknown action passed: ${action}`);
    }
  }
}

const useSearchReducerInitState: UseSearchReducerState = {
  lastQuery: "",
  searchState: UseSearchStates.INIT,
  results: [],
  timesErrored: 1,
};

export function useSearch(
  query: string,
  options?: PagefindSearchOptions,
): UseSearchReturn {
  // Load pagefind
  usePagefind();

  const [state, dispatch] = useReducer(
    useSearchReducer,
    useSearchReducerInitState,
  );

  useEffect(() => {
    // This happens when pagefind is still not found after 9.3s
    if (state.searchState === UseSearchStates.ERROR) {
      return;
    }

    // Check if pagefind exists
    if (!globalThis.pagefind) {
      // We've waited 9.3s now. It's time to give up
      if (state.timesErrored > 5) {
        dispatch({ type: UseSearchReducerActionType.ERROR_PAGEFIND_NOT_FOUND });

        return;
      }

      // Exponential backoff to double check if Pagefind has been loaded
      setTimeout(() => {
        dispatch({
          type:
            UseSearchReducerActionType.ERROR_HANDLING_RETRY_PAGEFIND_NOT_FOUND,
        });
      }, 150 * Math.pow(2, state.timesErrored));

      return;
    }

    // If this is a new run, reset state
    if (query !== state.lastQuery) {
      dispatch({ type: UseSearchReducerActionType.INIT_SETUP_NEW_QUERY });
    }

    // If the state isn't setting up a new run, don't do anything
    if (state.searchState !== UseSearchStates.INIT) {
      return;
    }

    const searchQuery = async () => {
      // Save last query and set state to loading first before doing anything
      // to prevent the effect from running again when still waiting for the
      // next result
      dispatch({
        type: UseSearchReducerActionType.SEARCH_PREPARE,
        payload: { lastQuery: query },
      });

      const toFetchResults = await window.pagefind.search(query, options);

      const results = toFetchResults.results.map((result) => result.data());

      dispatch({
        type: UseSearchReducerActionType.SEARCH_FINISH,
        payload: { results },
      });
    };

    searchQuery().then();
  }, [query, options, state]);

  return {
    searchState: state.searchState,
    results: state.results,
    forceSearch: () => {
      dispatch({ type: UseSearchReducerActionType.SEARCH_FORCE });
    },
  };
}
