export default function OwlWireframe() {
  return (
    <svg
      fill="none"
      stroke="black"
      height="50"
      viewBox="0 0 40 50"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3426 5.38778L11.8867 0.999985L11.7891 21.8664L1.01465 49.1196H31.7293L25.9764 42.4404L38.8473 21.8664V0.999985L25.3426 5.38778Z"
        strokeWidth="1.15479"
      />
      <path
        d="M11.7891 21.868L25.3189 17.9688L11.8968 1.02393"
        strokeWidth="1.15479"
      />
      <path d="M11.8857 11.7257L25.2929 17.9661" strokeWidth="1.15479" />
      <path d="M25.3418 5.38655V24.1577" strokeWidth="1.15479" />
      <path
        d="M38.8289 1.06071L25.4053 17.9195L38.8148 21.859"
        strokeWidth="1.15479"
      />
      <path d="M25.3848 17.9317L38.829 11.6967" strokeWidth="1.15479" />
      <path
        d="M21.9707 18.9845L25.3421 24.1283L28.8637 18.9722"
        strokeWidth="1.15479"
      />
      <path
        d="M11.8466 21.9124L25.3506 31.1437L1.02246 49.1136"
        strokeWidth="1.15479"
      />
      <path
        d="M38.8303 21.8484L25.3384 31.1662L25.974 42.4608L1.18457 49.1104"
        strokeWidth="1.15479"
      />
      <path
        d="M38.8143 21.8916L28.6246 32.6719L26.0049 42.4334"
        strokeWidth="1.15479"
      />
      <path
        d="M11.8797 21.9436L19.7652 30.7433L1 49.1357"
        strokeWidth="1.15479"
      />
      <path d="M25.9893 42.4738L28.1088 49.1141" strokeWidth="1.15479" />
    </svg>
  );
}
