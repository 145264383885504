import { MutableRefObject, useCallback, useRef } from "react";
import useNewsLetterSignUpForm, {
  SignUpState,
} from "../hooks/newsletter-signup-hooks.ts";

export default function NewsletterSignupForm() {
  const signup = useNewsLetterSignUpForm({ formId: "ergo-newsletter" });

  function statusText(state: SignUpState) {
    if (state.success) {
      return "You have successfully subscribed to The Ergo. Keep an eye on your inbox!";
    }

    if (state.error) {
      return state.message || "Unable to subscribe! Please try again later!";
    }

    if (state.submitting) {
      return "Submitting...";
    }

    if (state.invalid) {
      return "Please enter valid email.";
    }

    return "One issue per month, unsubscribe anytime";
  }

  const inputRef: MutableRefObject<HTMLInputElement | null> = useRef<
    HTMLInputElement | null
  >(null);

  const handleSubmit = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();

      if (signup.state.submitting) {
        return;
      }

      if (inputRef && inputRef.current) {
        const value = inputRef.current?.value;

        if (value) {
          signup.actions.submit({ value });
        }
      }
    },
    [signup.actions.submit],
  );

  return (
    <form onSubmit={handleSubmit}>
      <input ref={inputRef} name="email" placeholder="Email" type="text" />
      <button onClick={handleSubmit}>
        <img alt="The Ergo" src="/images/ergo-icon.webp" width={16} />Subscribe
      </button>
      <span>{statusText(signup.state)}</span>
      <a target="_blank" rel="noreferrer" href="https://zsa.io/the-ergo">
        See it first
      </a>
    </form>
  );
}
