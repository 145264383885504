import { useEffect } from "react";

export default function TagSelectionEffects() {
  useEffect(() => {
    const setScroll = () => {
      const tagSelection = document.getElementById("tag-selection");

      if (tagSelection) {
        const scroll = document.documentElement.scrollTop;

        if (scroll > 230) {
          tagSelection.classList.add("tag-selection-background");
        } else {
          tagSelection.classList.remove("tag-selection-background");
        }
      }
    };

    // Called to set the background correctly if we are remounted.
    setScroll();

    self.window.addEventListener("scroll", setScroll);

    return () => {
      self.window.removeEventListener("scroll", setScroll);
    };
  }, []);

  return <div />;
}
