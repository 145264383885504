export default function Hamburger() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="2" width="100%" height="4" fill="white" />
      <rect y="12" width="100%" height="4" fill="white" />
      <rect y="22" width="100%" height="4" fill="white" />
    </svg>
  );
}
