import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useSearch } from "../hooks/search-hooks.ts";

import SearchResults from "./search_results.tsx";
import Client_search_icon from "./client_search_icon.tsx";

export default function SearchPageContent() {
  const [query, setQuery] = useState<string>("");

  // Only on page load
  useEffect(() => {
    const param = new URLSearchParams(window.location.search).get("query") ||
      "";
    setQuery(param);
  }, []);

  const ref: MutableRefObject<HTMLInputElement | null> = useRef<
    HTMLInputElement | null
  >(null);

  const blog = useSearch(query, { filters: { source: "blog" } });

  const searchFocus = useCallback((element: HTMLElement) => {
    if (element) {
      element.scrollIntoView();
    }
  }, []);

  const onSubmit = () => {
    if (ref && ref.current) {
      setQuery(ref.current.value);

      window.history.replaceState(null, "", "?query=" + ref.current.value);
    }
  };

  if (query === undefined) {
    return null;
  }

  return (
    <>
      <section className="search-panel" ref={searchFocus}>
        <form onSubmit={onSubmit}>
          <Client_search_icon />
          <input
            className="search-panel-query"
            name="query"
            ref={ref}
            placeholder='Try "Tap Dance"'
            defaultValue={query}
            required
            minLength={2}
          />
          <input className="search-panel-submit" type="submit" value="Search" />
        </form>
      </section>
      <nav className="static-page-navigation">
        <a href="/">
          <span>&larr;</span>
          <span>Back to blog index</span>
        </a>
      </nav>
      <SearchResults blog={blog} />
    </>
  );
}
