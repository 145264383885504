import ReactDOMClient from "react-dom/client";

import MainNavigationMobile from "./client/components/main_navigation_mobile.tsx";
import ArticleHeaderEffects from "./client/components/article_header_effects.tsx";
import NewsletterSignupForm from "./client/components/newsletter_signup_form.tsx";
import ArticleNavigationEffects from "./client/components/article_navigation_effects.tsx";
import TagSelectionEffects from "./client/components/tag_selection_effects.tsx";
import SearchPageContent from "./client/components/search_page_content.tsx";
import NewsletterLightbox from "./client/components/newsletter_lightbox.tsx";

function render() {
  const mainNavigationMobile = document.getElementById(
    "main-navigation-mobile",
  );

  if (mainNavigationMobile) {
    const root = ReactDOMClient.createRoot(mainNavigationMobile);
    root.render(<MainNavigationMobile />);
  }

  const articleHeader = document.getElementById("article-header-effects");

  if (articleHeader) {
    const root = ReactDOMClient.createRoot(articleHeader);
    root.render(<ArticleHeaderEffects />);
  }

  const newsletterSignup = document.getElementById("newsletter-signup-form");

  if (newsletterSignup) {
    const root = ReactDOMClient.createRoot(newsletterSignup);
    root.render(<NewsletterSignupForm />);
  }

  const articleNavigation = document.getElementById(
    "article-navigation-effects",
  );

  if (articleNavigation) {
    const root = ReactDOMClient.createRoot(articleNavigation);
    root.render(<ArticleNavigationEffects />);
  }

  const tagSelectionEffects = document.getElementById("tag-selection-effects");

  if (tagSelectionEffects) {
    const root = ReactDOMClient.createRoot(tagSelectionEffects);
    root.render(<TagSelectionEffects />);
  }

  const searchPageContent = document.getElementById("search-page-content");

  if (searchPageContent) {
    const root = ReactDOMClient.createRoot(searchPageContent);
    root.render(<SearchPageContent />);
  }

  const newsletterLightbox = document.getElementById("newsletter-lightbox");

  if (newsletterLightbox) {
    const root = ReactDOMClient.createRoot(newsletterLightbox);
    root.render(<NewsletterLightbox />);
  }
}

render();
