import { useEffect } from "react";

export default function ArticleNavigationEffects() {
  useEffect(() => {
    const setScroll = () => {
      const articleNavigation = document.getElementById("article-navigation");

      if (articleNavigation) {
        const scroll = document.documentElement.scrollTop;
        const height = document.documentElement.clientHeight;
        const width = document.documentElement.clientWidth;

        const offset = width > 568 ? height * 0.5 : 0;

        if (scroll > height * 0.5) {
          articleNavigation.style.display = "flex";
        } else {
          articleNavigation.style.display = "none";
        }
        articleNavigation.style.opacity = `${
          Math.min(Math.floor(scroll / (height + offset)), 1)
        }`;
      }
    };

    globalThis.addEventListener("scroll", setScroll);

    return () => {
      globalThis.removeEventListener("scroll", setScroll);
    };
  }, []);

  return <div />;
}
