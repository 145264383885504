import { useCallback, useEffect, useReducer } from "react";

interface Map {
  [key: string]: string;
}

function encodeFormData(data: Map) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");
}

function validateEmail(email: string) {
  if (!email) return false;

  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(
      email,
    );
}

const EVENT_SUBMITTING = "SUBMITTING";
const EVENT_SUCCESS = "SUCCESS";
const EVENT_ERROR = "ERROR";
const EVENT_INVALID = "INVALID";
const EVENT_RESET = "RESET";

export type SignUpState = {
  submitting: boolean;
  error: boolean;
  success: boolean;
  invalid: boolean;
  message?: string;
};

type Event = {
  type: string;
  message?: string;
};

const defaultState: SignUpState = {
  submitting: false,
  error: false,
  success: false,
  invalid: false,
};

function stateReducer(
  state: SignUpState = defaultState,
  event: Event,
): SignUpState {
  switch (event.type) {
    case EVENT_RESET: {
      return { ...defaultState };
    }

    case EVENT_INVALID: {
      return { ...defaultState, submitting: false, invalid: true };
    }

    case EVENT_SUBMITTING: {
      return { ...state, submitting: true, error: false };
    }

    case EVENT_SUCCESS: {
      return { ...state, submitting: false, success: true };
    }

    case EVENT_ERROR: {
      return {
        ...defaultState,
        submitting: false,
        error: true,
        message: event.message,
      };
    }

    default: {
      return state;
    }
  }
}

interface SignUpProps {
  formId?: string;
}

interface SignUp {
  value: string;
}

export default function useNewsLetterSignUpForm({ formId }: SignUpProps = {}) {
  const [state, dispatch] = useReducer(stateReducer, defaultState);
  const reset = useCallback(() => {
    dispatch({ type: EVENT_RESET });
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const alreadySubscribed =
        window.localStorage.getItem("newsletter-subscribed") === "true";

      if (alreadySubscribed) {
        dispatch({ type: EVENT_SUCCESS });
      }
    }
  }, []);

  const submit = useCallback(
    ({ value }: SignUp) => {
      dispatch({ type: EVENT_SUBMITTING });

      if (state.submitting || state.success) {
        console.log(
          "did not send, form either invalid, submitting or already succeeded",
        );
        return;
      }

      const isValid = validateEmail(value);

      if (!isValid) {
        dispatch({ type: EVENT_INVALID, message: "Please enter valid email." });
        return;
      }

      send().then();

      async function send() {
        const email = value;
        dispatch({ type: EVENT_SUBMITTING });

        try {
          const response = await fetch(
            "https://api.zsa.io/v1/newsletter-sign-up",
            {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encodeFormData({
                "form-name": "the-ergo-subscription",
                email,
              }),
            },
          );

          if (response.status >= 300) {
            console.error(
              `Unable to subscribe, network error: ${response.status}`,
            );
            dispatch({
              type: EVENT_ERROR,
              message: "Unable to subscribe!!. Try again later.",
            });
          } else {
            dispatch({ type: EVENT_SUCCESS });
            window.localStorage.setItem("newsletter-subscribed", "true");
          }
        } catch (_e) {
          dispatch({
            type: EVENT_ERROR,
            message: "Unable to subscribe!!!. Try again later",
          });
        }
      }
    },
    [formId, state.submitting, state.success],
  );

  return {
    state,
    actions: {
      reset,
      submit,
    },
  };
}
