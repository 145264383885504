import {
  UsePromiseAllStates,
  UseSearchReturn,
  UseSearchStates,
} from "../types/search.ts";

import { usePromiseAll } from "../hooks/search-hooks.ts";
import SearchResult from "./search_result.tsx";

interface SearchContentProps {
  blog: UseSearchReturn;
}

function SearchContent({ blog }: SearchContentProps) {
  // Load just the first page, since we aren't paginating the search results page (yet)
  const { loadState: blogLoadState, results: blogResults } = usePromiseAll(
    blog.results,
  );

  if (
    blog.searchState !== UseSearchStates.FINISHED ||
    blogLoadState !== UsePromiseAllStates.FINISHED
  ) {
    return <section className="no-search-results">Loading...</section>;
  }

  if (blogResults.length === 0) {
    return <section className="no-search-results">No Results.</section>;
  }

  return (
    <section className="search-results" data-cy="results-container">
      {blogResults.map((item) => (
        <SearchResult key={`result_item_${item.url}`} item={item} />
      ))}
    </section>
  );
}

export default SearchContent;
