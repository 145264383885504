import {
  LegacyRef,
  MouseEvent,
  MutableRefObject,
  useEffect,
  useRef,
} from "react";

import OwlWireframe from "./owl_wireframe.tsx";
import NewsletterSignupForm from "./newsletter_signup_form.tsx";

export default function NewsletterLightbox() {
  const overlayRef: MutableRefObject<HTMLDivElement | null> = useRef<
    HTMLDivElement | null
  >(null);

  const lightboxTriggerObserverRef = useRef<IntersectionObserver | null>(null);
  const lightboxTimeoutRef = useRef<number | null>(null);
  const lightboxTriggerRef: LegacyRef<HTMLDivElement> = useRef<HTMLDivElement>(
    null,
  );

  function newsletterAlreadyViewed() {
    return (
      self.window.localStorage.getItem("subscriberLightbox") ||
      self.window.localStorage.getItem("newsletter-subscribed")
    );
  }

  const onOverlayClose = (
    e: MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (overlayRef && overlayRef.current) {
      overlayRef.current.classList.remove("show-overlay");
    }

    document.body.classList.remove("no-scroll");
    self.window.localStorage.setItem("subscriberLightbox", "1");
  };

  useEffect(() => {
    if (newsletterAlreadyViewed()) {
      return;
    }

    lightboxTriggerObserverRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (!lightboxTimeoutRef.current) {
          lightboxTimeoutRef.current = setTimeout(() => {
            if (overlayRef && overlayRef.current) {
              overlayRef.current.classList.add("show-overlay");
              document.body.classList.add("no-scroll");
            }
          }, 30000);
        }
      }
    });

    if (lightboxTriggerRef.current) {
      lightboxTriggerObserverRef.current.observe(lightboxTriggerRef.current);
    }

    return () => {
      lightboxTriggerObserverRef && lightboxTriggerObserverRef.current &&
        lightboxTriggerObserverRef.current.disconnect();
      lightboxTimeoutRef && lightboxTimeoutRef.current &&
        clearTimeout(lightboxTimeoutRef.current);

      // Cleanup code just in case something gets missed
      if (overlayRef && overlayRef.current) {
        overlayRef.current.classList.remove("show-overlay");
      }
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <div ref={lightboxTriggerRef} className="newsletter-lightbox">
      <div ref={overlayRef} className="newsletter-lightbox-overlay">
        <div className="newsletter-lightbox-dialog">
          <div className="newsletter-lightbox-inner-box">
            <div className="newsletter-lightbox-heading">
              <button onClick={onOverlayClose}>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none meet"
                  viewBox="0 0 40 40"
                  width="40"
                  height="40"
                  stroke="black"
                  fill="none"
                >
                  <line x1="0" x2="100%" y1="0" y2="100%" />
                  <line x1="0" x2="100%" y1="100%" y2="0" />
                </svg>
              </button>
            </div>
            <div className="newsletter-lightbox-content">
              <div className="newsletter-lightbox-title-box">
                <OwlWireframe />
                <h1>The Ergo</h1>
              </div>
              <div className="newsletter-lightbox-signup-quote">
                <p>
                  "Thank you for creating a newsletter I WANT to read, instead
                  of an auto delete."
                </p>
                <p>- Joel Jackson, one of over 60,000 subscribers</p>
              </div>
              <div className="newsletter-lightbox-signup-blurb">
                The Ergo is one of those rare newsletters that actually get fan
                mail on a regular basis. Also, if you subscribe below, you’ll
                get a coupon for <strong>10% off</strong> our awesome card deck,
                {" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://zsa.fun/cards"
                >
                  ZSA Cards
                </a>.
              </div>
              <div className="newsletter-signup-form newsletter-lightbox-signup-form">
                <NewsletterSignupForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
