import { MouseEvent, MutableRefObject, useEffect, useRef } from "react";
import Hamburger from "./hamburger.tsx";

export default function MainNavigationMobile() {
  const ref: MutableRefObject<HTMLDivElement | null> = useRef<
    HTMLDivElement | null
  >(null);

  useEffect(() => {
    return () => {
      // Clean-up code just in case something gets missed
      if (ref && ref.current) {
        ref.current.classList.remove("show-overlay");
      }
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const onButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (ref.current) {
      ref.current.classList.add("show-overlay");
      document.body.classList.add("no-scroll");
    }
  };

  const onOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (ref.current) {
      ref.current.classList.remove("show-overlay");
      document.body.classList.remove("no-scroll");
    }
  };

  const onLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();

    // Even though we are navigating off the page, we should still
    // clean up
    if (ref.current) {
      ref.current.classList.remove("show-overlay");
      document.body.classList.remove("no-scroll");
    }
  };

  return (
    <>
      <button onClick={onButtonClick}>
        <Hamburger />
      </button>
      <div
        ref={ref}
        className="main-navigation-mobile-overlay"
        onClick={onOverlayClick}
      >
        <nav className="main-navigation-mobile-dropdown">
          <section>
            <a onClick={onLinkClick} className="blog-branding" href="/">
              <img
                src="/images/on-tap-logo.webp"
                width="48"
                height="56"
                alt="Blog Logo"
              />
              <div>
                <img
                  src="/images/on-tap-text.webp"
                  width="74"
                  height="16"
                  alt="On Tap"
                />
                <span>Our Blog</span>
              </div>
            </a>
            <a
              onClick={onLinkClick}
              className="voyager-branding"
              href="https://zsa.io/voyager/"
            >
              <img
                src="/images/navigation-voyager-large.webp"
                width="150"
                height="43"
                alt="Voyager Logo"
              />
              <div>
                <img
                  src="/images/voyager-text.svg"
                  width="128"
                  height="26"
                  alt="Voyager"
                />
                <span>Low-profile Power</span>
              </div>
            </a>
            <a
              onClick={onLinkClick}
              className="moonlander-branding"
              href="https://zsa.io/moonlander/"
            >
              <img
                src="/images/navigation-moonlander-large.webp"
                width="150"
                height="59"
                alt="Moonlander Logo"
              />
              <div>
                <img
                  src="/images/moonlander-text.svg"
                  width="128"
                  height="23"
                  alt="Moonlander"
                />
                <span>
                  Next-gen
                  <br />
                  Ergonomics
                </span>
              </div>
            </a>
            <a
              onClick={onLinkClick}
              className="ergodox-branding"
              href="https://ergodox-ez.com"
            >
              <img
                src="/images/navigation-ergodox-large.webp"
                width="150"
                height="61"
                alt="Ergodox Logo"
              />
              <div>
                <img
                  src="/images/ergodox-text.svg"
                  width="115"
                  height="25"
                  alt="Ergodox"
                />
                <span>Custom Comfort</span>
              </div>
            </a>
            <a
              onClick={onLinkClick}
              className="ergo-branding"
              href="https://zsa.io/the-ergo/"
            >
              <img
                src="/images/navigation-the-ergo-large.svg"
                width="39"
                height="49"
                alt="The Ergo Logo"
              />
              <div>
                <img
                  src="/images/the-ergo-text.webp"
                  width="94"
                  height="16"
                  alt="The Ergo"
                />
                <span>Great Monthly News</span>
              </div>
            </a>
          </section>
        </nav>
      </div>
    </>
  );
}
