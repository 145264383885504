export default function Client_search_icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
    >
      <path
        d="M18.893 9.75C18.893 14.574 14.9445 18.5 10.0549 18.5C5.16524 18.5 1.2168 14.574 1.2168 9.75C1.2168 4.92597 5.16524 1 10.0549 1C14.9445 1 18.893 4.92597 18.893 9.75Z"
        stroke="black"
        strokeOpacity="0.75"
        strokeWidth="2"
      />
      <line
        y1="-1"
        x2="15.39"
        y2="-1"
        transform="matrix(0.710279 0.70392 -0.710279 0.70392 15.5205 15.167)"
        stroke="black"
        strokeOpacity="0.75"
        strokeWidth="2"
      />
    </svg>
  );
}
